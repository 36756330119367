<template>
    <b-form>
        <div class="card-loader-body">
            <b-row>
                <b-col cols="12" lg="6">
                    <b-form-group
                        id="input-gr-1"
                        label="Наименование:"
                        label-for="inp-21"
                    >
                        <b-form-input
                            id="inp-21"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.name"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group
                        id="input-gr-2"
                        label="Отчетный год:"
                        label-for="inp-22"
                    >
                        <b-form-input
                            id="inp-22"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.year"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group
                        id="input-gr-5"
                        label="Отчетный месяц:"
                        label-for="inp-25"
                    >
                        <b-form-input
                            id="inp-25"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.month"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group
                        id="input-group-2"
                        label="Вид бюджета:"
                        label-for="input-2"
                    >
                        <b-form-input
                            id="input-2"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.vid"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group
                        id="input-gr-3"
                        label="Единица измерения:"
                        label-for="inp-23"
                    >
                        <b-form-input
                            id="inp-23"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.mesuare"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                    <b-form-group
                        id="input-gr-4"
                        label="Регион (область, город, район):"
                        label-for="inp-24"
                    >
                        <b-form-input
                            id="inp-24"
                            @keydown.stop.prevent="keyEvent($event)"
                            @keyup.stop.prevent="keyEvent($event)"
                            @keypress.stop.prevent="keyEvent($event)"
                            v-model="propData.region"
                            placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-loader-bottom">
            <b-button variant="success" @click="checkHashAsync()"
                >Загрузить</b-button
            >
            <b-button variant="default" @click="onClear()">Отмена</b-button>
        </div>
        <b-tabs class="tabs-in-tabs">
            <b-tab title="Загружаемые данные">
                <div class="table-container">
                    <b-table
                        id="my-table-body"
                        sticky-header
                        bordered
                        head-variant="light"
                        :items="fileBody"
                        :fields="fields"
                        :table-variant="light"
                    >
                        <template #cell(code)="row" class="tabBody">
                            <div align="center" class="code_text">
                                {{ row.item.code }}
                            </div>
                        </template>
                        <template #cell(name)="row">
                            <div align="left" class="code_text">
                                {{ row.item.name }}
                            </div>
                        </template>
                        <template #cell(utv_budget)="row">
                            <div align="left" class="code_text">
                                {{ row.item.utv_budget }}
                            </div>
                        </template>
                        <template #cell(utoch_budget)="row">
                            <div align="left" class="code_text">
                                {{ row.item.utoch_budget }}
                            </div>
                        </template>
                        <template #cell(skor_plan)="row">
                            <div align="left" class="code_text">
                                {{ row.item.skor_plan }}
                            </div>
                        </template>
                        <template #cell(spf_plat)="row">
                            <div align="left" class="code_text">
                                {{ row.item.spf_plat }}
                            </div>
                        </template>
                        <template #cell(spf_obaz)="row">
                            <div align="left" class="code_text">
                                {{ row.item.spf_obaz }}
                            </div>
                        </template>
                        <template #cell(accept_obaz)="row">
                            <div align="left" class="code_text">
                                {{ row.item.accept_obaz }}
                            </div>
                        </template>
                        <template #cell(unpaid_obaz)="row">
                            <div align="left" class="code_text">
                                {{ row.item.unpaid_obaz }}
                            </div>
                        </template>
                        <template #cell(exec_prg)="row">
                            <div align="left" class="code_text">
                                {{ row.item.exec_prg }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab title="Журнал загрузки">
                <journal-table></journal-table>
            </b-tab>
        </b-tabs>
    </b-form>
</template>

<script>
import "../BudgetView.scss";
import { Ax } from "@/utils";
import JournalTable from "@/modules/budget/components/Journal";
import store from "@/services/store";
import axios from "axios";

export default {
    name: "FileLoader127",
    components: { JournalTable },
    props: {
        propData: {},
        fileBody: {},
        fileName: {},
        file: {},
        budgetList: [],
    },
    data() {
        return {
            light: "light",
            fields: [
                {
                    key: "code",
                    label: "Код бюджетной классификации",
                    sortable: false,
                },
                { key: "name", label: "Наименование", sortable: false },
                {
                    key: "utv_budget",
                    label: "Утвержденный бюджет",
                    sortable: false,
                },
                {
                    key: "utoch_budget",
                    label: "Уточненный бюджет",
                    sortable: false,
                },
                { key: "skor_plan", label: "Скоррект. план", sortable: false },
                {
                    key: "spf_plat",
                    label: "СПП и СПФ по платежам",
                    sortable: false,
                },
                {
                    key: "spf_obaz",
                    label: "СПП и СПФ по обязат.",
                    sortable: false,
                },
                {
                    key: "accept_obaz",
                    label: "Принятые обязат.",
                    sortable: false,
                },
                {
                    key: "unpaid_obaz",
                    label: "Неоплаченные обязат.",
                    sortable: false,
                },
                {
                    key: "exec_prg",
                    label: "Исп. поступ. бюджета и/или опл. обязат. по прг(ппр)",
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        userLogin() {
            return store.state.user.login;
        },
        async checkHashAsync() {
            try {
                if (
                    this.propData.name &&
                    this.propData.year &&
                    this.propData.month &&
                    this.propData.vid &&
                    this.propData.mesuare &&
                    this.propData.region
                ) {
                    if (this.propData.isExists === true) {
                        this.handleOk();
                    } else {
                        await this.on127Load(this.budgetList);
                    }
                } else {
                    console.warn("Файл не выбран");
                }
            } catch (error) {
                console.error("Error in CheckHashAsync:", error);
            }
        },

        handleOk() {
            this.$bvModal
                .msgBoxConfirm(
                    "Сохранение приведет к изменению ранее принятых данных",
                    {
                        title: "Подтвердите",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Сохранить",
                        cancelTitle: "Отменить",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then(async (value) => {
                    console.log("Save", value);
                    if (value) {
                        await this.on127Load(this.budgetList);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async on127Load(budgetList) {
            const user = this.userLogin();
            this.$emit("save-form127", true);
            await axios
                .post("/api/excel/save-form127", budgetList)
                .then((response) => {
                    if (response.status === 200) {
                        this.makeToast("success", "Данные успешно сохранены!");
                        this.$emit("save-form127", false);
                    } else {
                        this.error = error;
                        this.makeToast("danger", "Ошибка сохранения!");
                        this.$emit("save-form127", false);
                    }
                })
                .catch((error) => {
                    this.error = error;
                    this.makeToast("danger", "Ошибка сохранения!");
                    this.$emit("save-form127", false);
                });

            this.makeToast(
                "success",
                "Загрузка файла начата! Результат в журнале Загрузки",
                "Результат"
            );
            this.$emit("save-form127", false);
        },
        onClear(event) {
            console.log("onClear");
            this.$emit("form-clear127", []);
            this.propData.name = "";
            this.propData.vid = "";
            this.propData.mesuare = "";
            this.propData.region = "";
            this.propData.rep_date = "";
            this.propData.month = "";
            this.propData.year = "";
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 2000,
                solid: true,
            });
        },
        keyEvent(event) {},
    },
};
</script>

<style scoped>
.code_text {
    font-size: 0.8em;
}

.tabBody {
    font-weight: bold;
    font-style: italic;
    font-size: 1.8em;
    background-color: white;
}
</style>
