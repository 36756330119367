<template>
    <div class="inner-container">
        <div class="section-title budget">
            <i class="icon icon-calculator-coins"></i
            ><span>Бюджетное планирование</span>
        </div>
        <div class="section-subtitle">
            <i class="icon icon-grid"></i>Интеграции/загрузка
        </div>
        <b-tabs lazy>
            <b-tab title="План по доходам">
                <b-overlay
                    id="over1"
                    :show="busy1"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-1"
                                    label="Файл для загрузки:"
                                    label-for="file"
                                    description="Выберите файл формата .xls, .xlsx"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file"
                                            accept=".xls, .xlsx, .pdf"
                                            placeholder="Выберите файл"
                                            v-model="file1"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile1()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <file-loader
                        :prop-data="fileProp"
                        :file-body="fileBody"
                        :file-name="file1.name"
                        :file="file1"
                        v-on:form-clear="formClear"
                        v-on:save-load="saveLoad"
                    ></file-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 2-19">
                <b-overlay
                    id="over2"
                    :show="busy2"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-2"
                                    label="Файл для загрузки:"
                                    label-for="file219"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file219"
                                            accept=".xls, .xlsx, .pdf"
                                            placeholder="Выберите файл"
                                            v-model="file219"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile219()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <pdf-loader
                        :prop-data="fileProp219"
                        :file-body="fileBody219"
                        :file-name="file219.name"
                        :file="file219"
                        v-on:form-clear219="formClear219"
                        v-on:save-load219="saveLoad219"
                    ></pdf-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 2-43">
                <b-overlay
                    id="over3"
                    :show="busy3"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-2"
                                    label="Файл для загрузки:"
                                    label-for="file219"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file243"
                                            accept=".xls, .xlsx, .pdf"
                                            placeholder="Выберите файл"
                                            v-model="file243"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile243()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <pdf-loader243
                        :prop-data="fileProp243"
                        :file-body="fileBody243"
                        :file-name="file243.name"
                        :file="file243"
                        v-on:form-clear243="formClear243"
                        v-on:save-load243="saveLoad243"
                    ></pdf-loader243>
                </b-overlay>
            </b-tab>
            <b-tab title="План по расходам">
                <b-overlay
                    id="over5"
                    :show="busy5"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-5"
                                    label="Файл для загрузки:"
                                    label-for="file5"
                                    description="Выберите файл формата .xls, .xlsx"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file5"
                                            accept=".xls, .xlsx"
                                            placeholder="Выберите файл"
                                            v-model="file5"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile5()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <exp-loader
                        :prop-data="fileProp5"
                        :file-body="fileBody5"
                        :file-name="file5.name"
                        :file="file5"
                        v-on:form-clear="formClear5"
                        v-on:save-load="saveLoad5"
                    ></exp-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 5-52">
                <b-overlay
                    id="over4"
                    :show="busy4"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-4"
                                    label="Файл для загрузки:"
                                    label-for="file552"
                                    description="Выберите файл формата .pdf"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file552"
                                            accept=".pdf"
                                            placeholder="Выберите файл"
                                            v-model="file552"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile552()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <expend-loader
                        :prop-data="fileProp552"
                        :file-body="fileBody552"
                        :file-name="file552.name"
                        :file="file552"
                        v-on:form-clear552="formClear552"
                        v-on:save-load552="saveLoad552"
                    ></expend-loader>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 1-27">
                <b-overlay
                    id="over6"
                    :show="busy6"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-6"
                                    label="Файл для загрузки:"
                                    label-for="file127"
                                    description="Выберите файл формата .xls, .xlsx, .xml"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file127"
                                            accept=".xls, .xlsx, .xml"
                                            placeholder="Выберите файл"
                                            v-model="file127"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile127()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <file-loader127
                        :prop-data="fileProp127"
                        :file-body="fileBody127"
                        :file-name="fileProp127.name"
                        :file="file127"
                        :budget-list="fileHead127.budgetList"
                        v-on:form-clear127="formClear127"
                        v-on:save-load127="saveLoad127"
                    ></file-loader127>
                </b-overlay>
            </b-tab>
            <b-tab title="Форма 4-20">
                <b-overlay
                    id="over7"
                    :show="busy7"
                    rounded="sm"
                    :opacity="0.8"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                >
                    <div class="card-loader-top">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group
                                    id="form-file-7"
                                    label="Файл для загрузки:"
                                    label-for="file420"
                                    description="Выберите файл формата .xls, .xlsx"
                                >
                                    <b-form @submit.stop.prevent>
                                        <b-form-file
                                            id="file420"
                                            accept=".xls, .xlsx"
                                            placeholder="Выберите файл"
                                            v-model="file420"
                                            ref="file-input"
                                            browse-text="Обзор"
                                            @input="inputFile420()"
                                        >
                                        </b-form-file>
                                    </b-form>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <file-loader420
                        :prop-data="fileProp420"
                        :file-body="fileBody420"
                        :file-name="fileProp420.name"
                        :file="file420"
                        :budget-list="fileHead420.budgetList"
                        v-on:form-clear420="formClear420"
                        v-on:save-load420="saveLoad420"
                    ></file-loader420>
                </b-overlay>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Ax} from '@/utils';
import FileLoader from '@/modules/budget/components/FileLoader';
import PdfLoader from '@/modules/budget/components/PDFLoader219';
import PdfLoader243 from '@/modules/budget/components/PDFLoader243';
import ExpendLoader from '@/modules/budget/components/ExpendLoader';
import ExpLoader from '@/modules/budget/components/ExpLoader';
import FileLoader127 from '@/modules/budget/components/FileLoader127';
import FileLoader420 from '@/modules/budget/components/FileLoader420';

export default {
    name: 'BudgetLoader',
    // eslint-disable-next-line vue/no-unused-components
    components: {
        ExpendLoader,
        FileLoader,
        PdfLoader,
        PdfLoader243,
        ExpLoader,
        FileLoader127,
        FileLoader420,
    },
    data() {
        return {
            dangReg: 'Наименование',
            file1: [],
            file219: [],
            file243: [],
            file552: [],
            file5: [],
            file127: [],
            file420: [],
            fileHead: [],
            fileBody: [],
            fileHead5: [],
            fileBody5: [],
            fileHead219: [],
            fileBody219: [],
            fileHead243: [],
            fileBody243: [],
            fileHead552: [],
            fileBody552: [],
            fileHead127: [],
            fileBody127: [],
            fileHead420: [],
            fileBody420: [],
            fileName: false,
            fileProp: {
                name: '',
                vid: '',
                period: '',
                data: '',
                mesuare: '',
                region: '',
            },
            fileProp5: {
                name: '',
                vid: '',
                period: '',
                data: '',
                mesuare: '',
                region: '',
            },
            fileProp219: {
                name: '',
                data: '',
                mesuare: '',
                region: '',
                rep_date: '',
            },
            fileProp243: {
                name: '',
                period: '',
                data: '',
                mesuare: '',
                region: '',
                kod_profit: '',
            },
            fileProp552: {
                name: '',
                vid: '',
                spec: '',
                source: '',
                data: '',
                region: '',
            },
            fileProp127: {
                name: '',
                vid: '',
                year: '',
                day: '',
                month: '',
                mesuare: '',
                region: '',
                rep_date: '',
                isExists: false,
            },
            fileProp420: {
                name: '',
                vid: '',
                year: '',
                repdate: '',
                mesuare: '',
                region: '',
                abp: '',
                gu: '',
                isExists: false,
            },
            busy1: false,
            busy2: false,
            busy3: false,
            busy4: false,
            busy5: false,
            busy6: false,
            busy7: false,
            spfMap: new Map(),
        };
    },
    methods: {
        inputFile1() {
            const formData = new FormData();
            if (this.file1 !== null) {
                this.busy1 = true;
                formData.append('file', this.file1, this.file1.name);
                this.FilePPLoad(formData);
            }
        },
        async FilePPLoad(formData) {
            try {
                const response = await fetch('/api-py/check-hplan', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                });
                const result = await response.json();
                if (result === true) {
                    await this.fileHeadLoad(formData);
                } else {
                    this.busy1 = false;
                    this.makeToast(
                        'danger',
                        'Выбранный файл не соответствует шаблону!',
                        'Сообщение'
                    );
                }
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        fileHeadLoad(formData) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-hplan', // Заголовок файла
                        method: 'POST',
                        data: formData,
                    },
                    (data) => {
                        this.busy1 = true;
                        this.fileHead = JSON.parse(data);
                        if (this.fileHead !== null) {
                            this.fileProp.name =
                                this.fileHead[0].names + this.fileHead[1].names;
                            this.fileProp.vid = this.fileHead[2].value;
                            this.fileProp.period = this.fileHead[3].value;
                            this.fileProp.data = this.fileHead[4].value;
                            this.fileProp.mesuare = this.fileHead[5].value;
                            if (this.fileHead[6].value !== this.dangReg) {
                                this.fileProp.region =
                                    this.fileHead[6].value +
                                    ' (' +
                                    this.fileHead[6].regId +
                                    ')';
                            } else {
                                this.fileProp.region = '';
                            }
                            this.fileBodyLoad(formData);
                        }

                        //                    this.busy1 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy1 = false;
                    }
                );
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        fileBodyLoad(formData) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-bplan', //  Тело файла
                        method: 'POST',
                        data: formData,
                    },
                    (data) => {
                        this.busy1 = true;
                        this.fileBody = JSON.parse(data);
                        this.busy1 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy1 = false;
                    }
                );
            } catch (err) {
                this.busy1 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile5() {
            const formData5 = new FormData();
            if (this.file5 !== null) {
                this.busy5 = true;
                formData5.append('file', this.file5, this.file5.name);
                this.File5Load(formData5);
            }
        },
        async File5Load(formData5) {
            try {
                const response = await fetch('/api-py/check-hfact', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData5,
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead5(formData5);
                } else {
                    this.busy5 = false;
                    this.makeToast(
                        'danger',
                        'Выбранный файл не соответствует шаблону!',
                        'Сообщение'
                    );
                }
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead5(formData5) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-hfact', // Заголовок файла
                        method: 'POST',
                        data: formData5,
                    },
                    (data) => {
                        this.busy5 = true;
                        this.fileHead5 = JSON.parse(data);
                        if (this.fileHead5 !== null) {
                            this.fileProp5.name =
                                this.fileHead5[0].names +
                                this.fileHead5[1].names;
                            this.fileProp5.vid = this.fileHead5[2].value;
                            this.fileProp5.period = this.fileHead5[3].value;
                            this.fileProp5.data = this.fileHead5[4].value;
                            this.fileProp5.mesuare = this.fileHead5[5].value;
                            if (this.fileHead5[6].value !== this.dangReg) {
                                this.fileProp5.region =
                                    this.fileHead5[6].value +
                                    ' (' +
                                    this.fileHead5[6].regId +
                                    ')';
                            } else {
                                this.fileProp5.region = '';
                            }
                            this.pdfLoadBody5(formData5);
                        }
                        //this.busy5 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy5 = false;
                    }
                );
            } catch (err) {
                this.busy5 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody5(formData5) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-bfact', //  Тело файла
                        method: 'POST',
                        data: formData5,
                    },
                    (data) => {
                        this.busy5 = true;
                        this.fileBody5 = JSON.parse(data);
                        this.busy5 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy5 = false;
                    }
                );
            } catch (err) {
                this.busy5 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile219() {
            const formData219 = new FormData();
            if (this.file219 !== null) {
                this.busy2 = true;
                formData219.append('file', this.file219, this.file219.name);
                this.File219Load(formData219);
            }
        },
        async File219Load(formData219) {
            try {
                const response = await fetch('/api-py/check-h219', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData219,
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead(formData219);
                } else {
                    this.busy2 = false;
                    this.makeToast(
                        'danger',
                        'Выбранный файл не соответствует шаблону!',
                        'Сообщение'
                    );
                }
            } catch (err) {
                this.busy2 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead(formData219) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-h219', // Заголовок файла
                        method: 'POST',
                        data: formData219,
                    },
                    (data) => {
                        // запуск обновления данных о пользователе в общем хранилище
                        // возврат на предыдущую страницу
                        this.fileHead219 = JSON.parse(data);
                        this.fileProp219.name = this.fileHead219[0].fileName;
                        this.fileProp219.data = this.fileHead219[0].data;
                        this.fileProp219.mesuare = this.fileHead219[0].mesuare;
                        this.fileProp219.region = this.fileHead219[0].region;
                        this.fileProp219.rep_date =
                            this.fileHead219[0].rep_date;
                        this.fileProp219.month = this.fileHead219[0].month;
                        this.fileProp219.year = this.fileHead219[0].year;
                        this.pdfLoadBody(formData219);
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            } catch (err) {
                this.busy2 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody(formData219) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-b219', //  Тело файла
                        method: 'POST',
                        data: formData219,
                    },
                    (data) => {
                        this.busy2 = true;
                        this.fileBody219 = JSON.parse(data);
                        this.busy2 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy2 = false;
                    }
                );
            } catch (err) {
                this.busy2 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile243() {
            const formData243 = new FormData();
            if (this.file243 !== null) {
                this.busy3 = true;
                formData243.append('file', this.file243, this.file243.name);
                this.File243Load(formData243);
            }
        },
        async File243Load(formData243) {
            try {
                const response = await fetch('/api-py/check-h243', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData243,
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead243(formData243);
                } else {
                    this.busy3 = false;
                    this.makeToast(
                        'danger',
                        'Выбранный файл не соответствует шаблону!',
                        'Сообщение'
                    );
                }
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead243(formData243) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-h243', // Заголовок файла
                        method: 'POST',
                        data: formData243,
                    },
                    (data) => {
                        // запуск обновления данных о пользователе в общем хранилище
                        // возврат на предыдущую страницу
                        this.busy3 = true;
                        this.fileHead243 = JSON.parse(data);
                        this.fileProp243.name = this.fileHead243[0].fileName;
                        this.fileProp243.data = this.fileHead243[0].data;
                        this.fileProp243.mesuare = this.fileHead243[0].mesuare;
                        this.fileProp243.region = this.fileHead243[0].region;
                        this.fileProp243.period = this.fileHead243[0].period;
                        this.fileProp243.kod_profit =
                            this.fileHead243[0].kod_profit;
                        this.pdfLoadBody243(formData243);
                    },
                    (error) => {
                        this.error = error;
                        this.busy3 = false;
                    },
                    (this.busy3 = false)
                );
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody243(formData243) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-b243', //  Тело файла
                        method: 'POST',
                        data: formData243,
                    },
                    (data) => {
                        this.busy3 = true;
                        this.fileBody243 = JSON.parse(data);
                        this.busy3 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy3 = false;
                    }
                );
            } catch (err) {
                this.busy3 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile552() {
            const formData552 = new FormData();
            if (this.file552 !== null) {
                formData552.append('file', this.file552, this.file552.name);
                this.File552Load(formData552);
            }
        },
        async File552Load(formData552) {
            try {
                const response = await fetch('/api-py/check-h552', {
                    method: 'POST',
                    credentials: 'include',
                    body: formData552,
                });
                const result = await response.json();
                console.log(result);
                if (result === true) {
                    await this.pdfLoadHead552(formData552);
                } else {
                    this.busy4 = false;
                    this.makeToast(
                        'danger',
                        'Выбранный файл не соответствует шаблону!',
                        'Сообщение'
                    );
                }
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadHead552(formData552) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-h552', // Заголовок файла
                        method: 'POST',
                        data: formData552,
                    },
                    (data) => {
                        this.busy4 = true;
                        this.fileHead552 = JSON.parse(data);
                        this.fileProp552.name = this.fileHead552[0].fileName;
                        this.fileProp552.data = this.fileHead552[0].data;
                        this.fileProp552.vid = this.fileHead552[0].vid;
                        this.fileProp552.region = this.fileHead552[0].region;
                        this.fileProp552.source = this.fileHead552[0].source;
                        this.fileProp552.spec = this.fileHead552[0].spec;
                        this.pdfLoadBody552(formData552);
                    },
                    (error) => {
                        this.error = error;
                        this.busy4 = false;
                    },
                    (this.busy4 = false)
                );
            } catch (err) {
                this.busy4 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        pdfLoadBody552(formData552) {
            try {
                Ax(
                    {
                        url: '/api-py/upload-b552', //  Тело файла
                        method: 'POST',
                        data: formData552,
                    },
                    (data) => {
                        this.busy4 = true;
                        this.fileBody552 = JSON.parse(data);
                        this.busy4 = false;
                    },
                    (error) => {
                        this.error = error;
                        this.busy4 = false;
                    }
                );
            } catch (err) {
                this.busy4 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },

        async inputFile127() {
            const formData127 = new FormData();
            if (this.file127 !== null) {
                this.busy6 = true;
                formData127.append('file', this.file127, this.file127.name);
                if (this.file127.name.endsWith('xls') || this.file127.name.endsWith('xlsx')) {
                    this.fileLoadHead127(formData127);
                } else {
                    this.busy6 = true;
                    try {
                        await this.filePreLoad127_xml(formData127);
                    } finally {
                        this.busy6 = false;
                    }
                }
            }
        },

        filePreLoad127_xml(formData127) {
            const reader = new FileReader();
            reader.readAsText(this.file127);
            reader.onload = async (event) => {
                this.busy6 = true;
                const xmlString = event.target.result;
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
                // Преобразуем XML в строку для отображения
                const xmlContent = new XMLSerializer().serializeToString(xmlDoc);
                const document = xmlDoc.getElementsByTagName('repdate')[0].textContent;
                this.fileProp127.name = xmlDoc.documentElement.getAttribute('type');
                const [day, month, year] = xmlDoc.getElementsByTagName('repdate')[0].textContent.split('.');
                this.fileProp127.month = month;
                this.fileProp127.year = year;
                this.fileProp127.day = day;
                this.fileProp127.mesuare = 'тенге';
                this.fileProp127.rep_date = xmlDoc.getElementsByTagName('repdate')[0].textContent;
                this.fileProp127.region = await this.getRegionByCode(xmlDoc.getElementsByTagName('obl')[0].textContent + xmlDoc.getElementsByTagName('reg')[0].textContent);
                this.fileProp127.vid = xmlDoc.getElementsByTagName('bud')[0].textContent;
                const rows_array = xmlDoc.getElementsByTagName('row');
                const table_items = [];
                const data_items = [];
                let ebk_data = []
                this.fileLoadHead127_xml(formData127);
                const result = await fetch('/api-py/dict-ebk-func-actual-code-date/2024-06-18');
                if (result.status === 200) {
                    ebk_data = await result.json();
                }
                for (const row of rows_array) {
                    const table_row = {
                        code: await this.generate_code_by_xml_row(row),
                        name: await this.get_code_name_by_xml_row(row),
                        utv_budget: Number(row.getElementsByTagName('utv')[0].textContent),
                        utoch_budget: Number(row.getElementsByTagName('utch')[0].textContent),
                        skor_plan: Number(row.getElementsByTagName('plg')[0].textContent),
                        spf_obaz: Number('0'),
                        spf_plat: Number(row.getElementsByTagName('plgp')[0].textContent),
                        accept_obaz: Number('0'),
                        unpaid_obaz: Number('0'),
                        exec_prg: Number(row.getElementsByTagName('sumrg')[0].textContent)
                    }

                    const data_row = {
                        budgetType: this.get_row_data(row, 'bud', 'bud'),
                        code: this.get_row_data(row, 'obl', 'bud') + this.get_row_data(row, 'reg', 'reg'),
                        codeCurrency: 'Тенге',
                        isValid: false,

                        pcl_abp: this.removeLeadingZeros(this.get_row_data(row, 'pkl', 'uch')),
                        spf_prg: this.removeLeadingZeros(this.get_row_data(row, 'prg', 'spk')),
                        ppr: this.removeLeadingZeros(this.get_row_data(row, 'pprg', 'pprg')),
                        spf: (this.get_row_data(row, 'kat', 'kat')==null) ? this.removeLeadingZeros(this.get_row_data(row, 'spk', 'spk')):"",
                        year: year,
                        month: month,
                        nplobz: Number('0'),
                        obz: this.get_row_data(row, 'obz', 'obz', 0)!=0 ? this.get_row_data(row, 'obz', 'obz', 0)/1000:0,
                        plg: this.get_row_data(row, 'plg', 'plg', 0)!=0 ? this.get_row_data(row, 'plg', 'plg', 0)/1000:0,
                        plgo: this.get_row_data(row, 'plgo', 'plgo', 0)!=0 ? this.get_row_data(row, 'plgo', 'plgo', 0)/1000:0,
                        plgp: this.get_row_data(row, 'plgp', 'plgp', 0)!=0 ? this.get_row_data(row, 'plgp', 'plgp', 0)/1000:0,
                        sumrg: this.get_row_data(row, 'sumrg', 'sumrg', 0)!=0 ? this.get_row_data(row, 'sumrg', 'sumrg', 0)/1000:0,
                        utch: this.get_row_data(row, 'utch', 'utch', 0)!=0 ? this.get_row_data(row, 'utch', 'utch', 0)/1000:0,
                        utv: this.get_row_data(row, 'utv', 'utv', 0)!=0 ? this.get_row_data(row, 'utv', 'utv', 0)/1000:0,
                        reportDate: this.convertToTimestampUTC(this.get_row_data(row, 'repdate')),
                        title: await this.get_code_name_by_xml_row(row),
                        username: 'user_test_xml',
                    }
                    if (row.getElementsByTagName('uch').length > 0) {
                        const code = ebk_data.filter(value => (value.abp==row.getElementsByTagName("uch")[0].textContent
                            & value.prg==row.getElementsByTagName("prg")[0].textContent & value.ppr==row.getElementsByTagName("pprg")[0].textContent));
                        if (code.length>0) {
                            data_row.kat_gr = code[0].gr;
                            data_row.cls_pgr = code[0].pgr;
                        }
                    } else {
                        data_row.kat_gr = this.removeLeadingZeros(this.get_row_data(row, 'kat', 'gr'));
                        data_row.cls_pgr = this.removeLeadingZeros(this.get_row_data(row, 'kls', 'kls'));
                    }
                    data_items.push(data_row);
                    table_items.push(table_row);
                }
                this.fileBody127 = table_items;
                this.fileHead127.budgetList = data_items;
                this.busy6 = false;
            };
        },

        removeLeadingZeros(str) {
            const number = parseInt(str, 10);
            return isNaN(number) ? '0' : number.toString();
        },

        convertToTimestampUTC(dateString) {
            const [day, month, year] = dateString.split('.').map(Number);
            const timestamp = Date.UTC(year, month - 1, day);
            return timestamp;
        },

        get_row_data(xml_row, field, field_alt, defult) {
            if (xml_row.getElementsByTagName(field).length > 0) {
                return xml_row.getElementsByTagName(field)[0].textContent
            } else if (xml_row.getElementsByTagName(field_alt).length > 0) {
                return xml_row.getElementsByTagName(field_alt)[0].textContent
            } else {
                if (defult != undefined) {
                    return defult
                } else {
                    return null
                }
            }
        },

        async generate_code_by_xml_row(xml_row) {
            if (xml_row.getElementsByTagName('kat').length > 0) {
                const result = xml_row.getElementsByTagName('kat')[0].textContent +
                    xml_row.getElementsByTagName('kls')[0].textContent + xml_row.getElementsByTagName('pkl')[0].textContent +
                    xml_row.getElementsByTagName('spk')[0].textContent;
                return result;
            } else {
                const result = xml_row.getElementsByTagName('uch')[0].textContent +
                    xml_row.getElementsByTagName('prg')[0].textContent + xml_row.getElementsByTagName('pprg')[0].textContent +
                    xml_row.getElementsByTagName('spk')[0].textContent;
                return result;

            }
        },

        async get_code_name_by_xml_row(xml_row) {
            if (xml_row.getElementsByTagName('kat').length > 0) {
                const param = {
                    kat: xml_row.getElementsByTagName('kat')[0].textContent,
                    cls: xml_row.getElementsByTagName('kls')[0].textContent,
                    pcl: xml_row.getElementsByTagName('pkl')[0].textContent,
                    spf: xml_row.getElementsByTagName('spk')[0].textContent
                }
                const result = await fetch('/api-py/get-income-ebk-code', {
                    method: 'POST',
                    body: JSON.stringify(param)
                })
                if (result.status === 200) {
                    const data = await result.json();
                    const res = data.ebk_code[3].name_ru;
                    return res
                } else {
                    this.makeToast('danger', 'get-budget-clarify-rate', `${result.status} - ${result.statusText}`);
                    return;
                }
            } else {
                const spf = xml_row.getElementsByTagName('spk')[0].textContent;
                const num = xml_row.getAttribute('num');
                if (this.spfMap.has(spf)) {
                    const data = this.spfMap.get(spf);
                    let res = 'Нет специфики'
                    if (data.length > 0) {
                        if (data[0]) {
                            res = data[0].name_ru;
                        }
                    } else {
                        if (data) {
                            if (data.name_ru) {
                                res = data.name_ru;
                            }
                        }
                    }
                    return res
                }
                const result = await fetch('/api-py/dict-ebk-spf/' + spf);
                if (result.status === 200) {
                    const data = await result.json();
                    this.spfMap.set(spf, data);
                    let res = 'Нет специфики'
                    if (data.length > 0) {
                        if (data[0]) {
                            res = data[0].name_ru;
                        }
                    } else {
                        if (data) {
                            if (data.name_ru) {
                                res = data.name_ru;
                            }
                        }
                    }
                    return res
                } else {
                    this.makeToast('danger', 'get_code_name_by_xml_row', `${result.status} - ${result.statusText}`);
                    return;
                }
            }

        },

        fileLoadHead127_xml(formData127) {
            try {
                const date = this.fileProp127.year +'-'+ this.fileProp127.month +'-' + this.fileProp127.day + ' 00:00:00:00';
                console.debug("date", date);
                Ax(
                    {
                        url: '/api/excel/upload-form127?report-date='+date+'&region='+this.fileProp127.region.substring(0, 6)+'&budget-type='+this.fileProp127.vid.substring(1), //  Тело файла
                        method: 'POST',
                        data: formData127,
                    },
                    (data) => {
                        this.fileHead127 = data;
                        this.fileProp127.isExists =
                            this.fileHead127.headerDto.isExists;
                        // this.fileBody552 = JSON.parse(data);
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            } catch (err) {
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        async fileLoadHead127(formData127) {
            try {
                const response = Ax(
                    {
                        url: '/api/excel/upload-form127',
                        method: 'POST',
                        data: formData127,
                    },
                    (data) => {
                        this.fileHead127 = data;
                        this.busy6 = false;
                        //    console.log(data);
                        this.fileProp127.name =
                            this.fileHead127.headerDto.title;
                        this.fileProp127.mesuare =
                            this.fileHead127.headerDto.unit;
                        this.fileProp127.region = `${this.fileHead127.headerDto.region} - ${this.fileHead127.headerDto.regionName}`;
                        this.fileProp127.rep_date =
                            this.fileHead127.headerDto.reportDate[2];
                        this.fileProp127.month =
                            this.fileHead127.headerDto.reportDate[1];
                        this.fileProp127.year =
                            this.fileHead127.headerDto.reportDate[0];
                        this.fileProp127.vid = `${this.fileHead127.headerDto.budgetType} - ${this.fileHead127.headerDto.budgetName}`;
                        this.fileHead127.budgetList.forEach((item) => {
                            this.fileBody127.push({
                                code: `${item.kat_gr}${item.cls_pgr}${
                                    item.pcl_abp
                                }${item.spf_prg}${item.ppr ? item.ppr : ''}${
                                    item.spf ? item.spf : ''
                                }`,
                                name: item.title,
                                utv_budget: item.utv,
                                utoch_budget: item.utch,
                                skor_plan: item.plg,
                                spf_plat: item.plgp,
                                spf_obaz: item.plgo,
                                accept_obaz: item.obz,
                                unpaid_obaz: item.nplobz,
                                exec_prg: item.sumrg,
                            });
                        });
                        this.fileProp127.isExists =
                            this.fileHead127.headerDto.isExists;
                    },
                    (error) => {
                        this.error = error;
                        this.makeToast(
                            'danger',
                            'Выбранный файл не соответствует шаблону!',
                            'Сообщение'
                        );
                        this.busy6 = false;
                    }
                );
            } catch (err) {
                this.busy6 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        inputFile420() {
            const formData420 = new FormData();
            if (this.file420 !== null) {
                this.busy7 = true;
                formData420.append('file', this.file420, this.file420.name);
                this.fileLoadHead420(formData420);
            }
        },
        fileLoadHead420(formData420) {
            try {
                const response = Ax(
                    {
                        url: '/api/excel/upload-form420',
                        method: 'POST',
                        data: formData420,
                    },
                    (data) => {
                        this.fileHead420 = data;
                        this.busy7 = false;
                        // console.log(data);
                        this.fileProp420.name =
                            this.fileHead420.headerDto.title;
                        this.fileProp420.mesuare =
                            this.fileHead420.headerDto.unit;
                        this.fileProp420.region = `${this.fileHead420.headerDto.region} - ${this.fileHead420.headerDto.regionName}`;
                        this.fileProp420.repdate = `${
                            this.fileHead420.headerDto.reportDate[2]
                        }.${
                            this.fileHead420.headerDto.reportDate[1] < 10
                                ? '0' + this.fileHead420.headerDto.reportDate[1]
                                : this.fileHead420.headerDto.reportDate[1]
                        }.${this.fileHead420.headerDto.reportDate[0]}`;
                        this.fileProp420.year =
                            this.fileHead420.headerDto.reportDate[0];
                        this.fileProp420.vid = `${this.fileHead420.headerDto.budgetType} - ${this.fileHead420.headerDto.budgetName}`;
                        this.fileProp420.abp = this.fileHead420.headerDto.abp;
                        this.fileProp420.gu = `${this.fileHead420.headerDto.gu} - ${this.fileHead420.headerDto.guName}`;
                        this.fileHead420.budgetList.forEach((item) => {
                            this.fileBody420.push({
                                code: item.func,
                                code_spf: item.spf,
                                name: item.funcName,
                                spf: item.plg,
                                spf_plat: item.plg,
                                spf_obaz: item.obaz,
                                accept_obaz: item.registrob,
                                paid_obaz_year: item.sumrg,
                                paid_obaz_month: item.currSumrg,
                                unpaid_obaz: item.notexec,
                            });
                        });
                        this.fileProp420.isExists =
                            this.fileHead420.headerDto.isExists;
                    },
                    (error) => {
                        this.error = error;
                        this.makeToast(
                            'danger',
                            'Выбранный файл не соответствует шаблону!',
                            'Сообщение'
                        );
                        this.busy7 = false;
                    }
                );
            } catch (err) {
                this.busy7 = false;
                this.makeToast('danger', err.toString(), 'Ошибка запроса');
            }
        },
        keyEvent(event) {
        },
        formClear(filt) {
            this.file1 = filt;
            this.fileBody = [];
        },
        formClear5(filt) {
            this.file5 = filt;
            this.fileBody5 = [];
        },
        formClear219(filt) {
            this.file219 = filt;
            this.fileBody219 = [];
        },
        formClear243(filt) {
            this.file243 = filt;
            this.fileBody243 = [];
        },
        formClear552(filt) {
            this.file552 = filt;
            this.fileBody552 = [];
        },
        formClear127(filt) {
            this.file127 = filt;
            this.fileBody127 = [];
        },
        formClear420(filt) {
            this.file420 = filt;
            this.fileBody420 = [];
        },
        saveLoad(param) {
            this.busy1 = param;
        },
        saveLoad5(param) {
            this.busy5 = param;
        },
        saveLoad219(param) {
            this.busy2 = param;
        },
        saveLoad243(param) {
            this.busy3 = param;
        },
        saveLoad552(param) {
            this.busy4 = param;
        },
        saveLoad127(param) {
            this.busy6 = param;
        },
        saveLoad420(param) {
            this.busy7 = param;
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 2000,
                solid: true,
            });
        },
        parseDateString(dateString) {
            const [year, month, day] = dateString.split('-');
            return new Date(`${year}-${month}-${day}`);
        },
        async getRegionByCode(code) {
            try {
                const request = await fetch('/api-py/dict-region-by-code/' + code);
                const response = await request.json();
                if (response.length == 1) {
                    return response[0].code + ' - ' + response[0].name_ru;
                } else if (response.length > 1) {
                    for (const reg of response) {
                        if (this.parseDateString(reg.end_date) > Date.now()) {
                            const res = reg.code + ' - ' + reg.name_ru;
                            return res;
                        }
                    }
                } else {
                    return code
                }
            } catch (e) {
                return e
            } finally {
            }
        }
    },
    computed: {},
};
</script>

<style scoped></style>
